<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="twelve-months-rolling" :class="{selected: activeTab === 'twelve-months-rolling'}"  @click="onTabClick">
          {{ $t(translationPath + 'twelveMonthsRollingTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="twelve-months-this-year" :class="{selected: activeTab === 'twelve-months-this-year'}"  @click="onTabClick">
          {{ $t(translationPath + 'twelveMonthsThisYearTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="all-months" :class="{selected: activeTab === 'all-months'}"  @click="onTabClick">
          {{ $t(translationPath + 'allMonthsTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="years" :class="{selected: activeTab === 'years'}"  @click="onTabClick">
          {{ $t( translationPath + 'yearsTab') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    translationPath: {},
    active: {
      type: String,
      default: 'all-months'
    }
  },
  name: 'AccountGroupReport2TabMenu',
  data () {
    return {
      activeTab: null
    }
  },
  created () {
    this.activeTab = this.active
  },
  methods: {
    onTabClick (event) {
      this.activeTab = event.target.attributes.tab.value
      this.$emit('tab-switched', event.target.attributes.tab.value)
    },
    changeTab (tab) {
      this.activeTab = tab
      this.$emit('tab-switched', tab)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
</style>
