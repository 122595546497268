<template>
  <div class="account-group-report-wrapper">
    <!-- START GRADIEND HEADER -->
    <b-container class="main-gradient light chart-container-wrapper" fluid>
      <b-container class="main-gradient-header-wrapper">
        <b-row class="main-gradient-tab-menu-wrapper mb-4" no-gutters>
          <b-col sm="12" no-gutters>
            <b-breadcrumb :items="breadcrumbItems" class="breadcrumb-light"></b-breadcrumb>
          </b-col>
          <b-col sm="12" no-gutters>
            <h1 class="text-left">{{apiData !== null ? ( Object.keys(apiData).indexOf('accountGroup') > -1 && Object.keys(apiData.accountGroup).indexOf('title') > -1 ? apiData.accountGroup.title : $t('reports.performance.account_group_report')) : $t('reports.performance.account_group_report')}}</h1>
          </b-col>
          <b-col sm="12" md="12" no-gutters>
            <account-group-report2-tab-menu ref="Tabmenu" :translationPath="'incomes_expenses.accounts.view.'" @tab-switched="onTabSwitched" :active="this.activeTab"></account-group-report2-tab-menu>
          </b-col>
        </b-row>
      </b-container>

      <b-row class="main-gradient-chart-wrapper">
        <div class="w-100 pb-3">
          <template v-if="currentCOA.locale">
            <account-group-report2-chart v-if="activeTab === 'twelve-months-rolling'"
              :data="apiData ? apiData.aggregated.rolling_12_months : {}"
              :noTransactions="transactionsTableBusy ? false : (transactionsTableRows.length ? false : true)"
              :currencySymbol="currentCOA.currency ? currentCOA.currency : ''"
              type="rolling"
            ></account-group-report2-chart>
            <account-group-report2-chart v-if="activeTab === 'twelve-months-this-year'"
              :data="apiData ? currentYearData : {}"
              :noTransactions="transactionsTableBusy ? false : (transactionsTableRows.length ? false : true)"
              :currencySymbol="currentCOA.currency ? currentCOA.currency : ''"
              type="year"
            ></account-group-report2-chart>
            <account-group-report2-chart v-if="activeTab === 'all-months'"
              :data="apiData ? apiData.aggregated : {}"
              :noTransactions="transactionsTableBusy ? false : (transactionsTableRows.length ? false : true)"
              :currencySymbol="currentCOA.currency ? currentCOA.currency : ''"
              type="allMonths"
            ></account-group-report2-chart>
            <account-group-report2-chart v-if="activeTab === 'years'"
              :data="apiData ? apiData.aggregated.years : {}"
              :noTransactions="transactionsTableBusy ? false : (transactionsTableRows.length ? false : true)"
              :currencySymbol="currentCOA.currency ? currentCOA.currency : ''"
              type="annual"
            ></account-group-report2-chart>
          </template>
        </div>
      </b-row>
    </b-container>
    <!-- END GRADIENT HEADER -->

    <b-container class="main-content-wrapper pt-0 px-0">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto mb-4 mt-4">
            <template v-slot:header>
              <b-row>
                <b-col lg="6">
                  <h2 v-if="apiData">{{ apiData.accountGroup.title }}</h2><h2 v-else>&nbsp;</h2>
                </b-col>
                <b-col lg="6">
                  <template v-if="apiData">
                    <b-link class='btn btn-sm icon-btn text-regular btn-outline-secondary actionButtons float-right ml-3' :to="groupEditLink">
                      <i class="flaticon solid edit-3 text-primary"></i>
                      {{ $t('common.edit') }}
                    </b-link>
                    <button class='btn btn-sm icon-btn text-regular btn-outline-secondary actionButtons float-right' @click="onBtnDelete">
                      <i class="flaticon solid trash-3" style="color: #e84752;"></i>
                      {{ $t('common.delete') }}
                    </button>
                  </template>
                </b-col>
              </b-row>
            </template>

            <b-row v-if="apiData">
              <b-col>
                <b-card-group deck>
                  <b-card class="invisible-card" :title="$t('reports.performance.account_group.group_code')">
                    <span class="text-regular font-weight-medium">{{ apiData.accountGroup.code }}</span>
                  </b-card>
                  <b-card class="invisible-card" :title="$t('reports.performance.account_group.group_type')">
                    <span class="text-regular font-weight-medium">{{ $te('common.' + apiData.accountGroup.type) ? $t('common.' + apiData.accountGroup.type) : apiData.accountGroup.type }}</span>
                  </b-card>
                  <template v-if="['asset', 'liability'].indexOf(apiData.accountGroup.type) === -1">
                    <b-card class="invisible-card" :title="$t('reports.performance.account_group.total_sum')">
                      <span v-if="currentCOA.locale" class="text-regular font-weight-medium">{{ currentYearTotal | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}</span>
                    </b-card>
                    <b-card class="invisible-card" :title="$t('reports.performance.account_group.total_sum_month')">
                      <span v-if="currentCOA.locale" class="text-regular font-weight-medium">{{ currentYearAvg | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}</span>
                    </b-card>
                  </template>
                </b-card-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <account-group-report2-accounts-table
        :accounts="this.apiData ? this.apiData.accounts: []"
        :groupType="this.group ? this.group.type : null"
        :totalColumn="apiData && ['asset', 'liability'].indexOf(apiData.accountGroup.type) === -1"
        :averageColumn="apiData && ['asset', 'liability'].indexOf(apiData.accountGroup.type) === -1"
      ></account-group-report2-accounts-table>

      <b-row no-gutters>
        <b-col style="width: 100%;">
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto mt-4">
            <template v-slot:header>
              <h2>{{ $t(translationPath + 'transactions_table_heading', { group: apiData ? apiData.accountGroup.title : '' }) }}</h2>
            </template>

            <p class="col-lg-8">
              {{ $t(translationPath + 'transactions_table_description')}}
              <b-link :to="'/transactions/view' + (apiData ? '?account_group_id=' + apiData.accountGroup.id : '')">{{ $t(translationPath + 'open_transactions_in_ledger')}}</b-link>
            </p>

            <performance-report-transaction-table
              ref="transactionsTable"
              :transactions="transactionsTableRows"
              :isEditBtn="false"
              :perPage="transactionsTablePerPage"
              :totalRows="transactionsTableTotalRows"
              :emptyText="$t('common.no_transaction')"
              :displayOffsetAccount="true"
              :enableRowDetailed="false"
              :accountType="apiData ? apiData.accountGroup.type : ''"
              :isDeleteBtn="true"
              :transactionsMetaData="transactionsTableRowsMeta"
              :busy="transactionsTableBusy"
              @update:perPage="onTransactionsTablePerPageUpdate"
              @update:currentPage="onTransactionsTableCurrentPageUpdate"
              @onFiltered="onTransactionsTableFiltered"
              @on-sort="onTransactionsTableSort"
              @delete-transaction="onBtnDeleteTransaction"
              @delete-multiple-transactions="onBtnDeleteTransactions"
            ></performance-report-transaction-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <delete-account-group-modal
      ref="deleteAccountGroupModal"
      :itemName="apiData ? apiData.accountGroup.title : ''"
      @on-delete-confirm="deleteAccountGroup"
    ></delete-account-group-modal>
    <account-group-prevent-delete
      ref="deleteAccountGroupPreventModal"
      :itemName="apiData ? apiData.accountGroup.title : ''"
    ></account-group-prevent-delete>
    <delete-transaction-modal
      ref="deleteTransactionModal"
      :itemName="transactionToDelete ? transactionToDelete.title : null"
      @hide="resetDeleteTransactionModals()"
      @on-delete-confirm="deleteTransaction($refs.deleteTransactionModal)"
    ></delete-transaction-modal>
    <delete-modal
      ref="deleteTransactionsModal"
      :message="$t('transactions.view.toast.confirm_delete_message')"
      @hide="resetDeleteTransactionModals()"
      @on-delete-confirm="deleteTransaction($refs.deleteTransactionsModal)"
    ></delete-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import axios from 'axios'
import AccountGroupReport2TabMenu from './AccountGroupReport2TabMenu'
import AccountGroupReport2Chart from './AccountGroupReport2Chart'
import formatAmount from '@/assets/filters/formatAmount'
import DeleteModal from '@/components/modals/DeleteModal'
import DeleteAccountGroupModal from '@/components/modals/DeleteAccountGroupModal'
import DeleteTransactionModal from '@/components/modals/DeleteTransactionModal'
import AccountGroupPreventDelete from '@/components/modals/AccountGroupPreventDelete'
import PerformanceReportTransactionTable from '../Account/PerformanceReportTransactionTable.vue'
import AccountGroupReport2AccountsTable from './AccountGroupReport2AccountsTable'

export default {
  name: 'AccountGroupReport2',
  components: {
    AccountGroupReport2TabMenu,
    AccountGroupReport2Chart,
    DeleteModal,
    DeleteAccountGroupModal,
    DeleteTransactionModal,
    AccountGroupPreventDelete,
    PerformanceReportTransactionTable,
    AccountGroupReport2AccountsTable
  },
  mixins: [titleMixins],
  filters: { formatAmount },
  data () {
    return {
      activeTab: 'twelve-months-rolling',
      apiData: null,
      group: null,
      transactionsTitleFilter: '',
      transactionsTableBusy: true,
      transactionsTablePerPage: 25,
      transactionsTableCurrentPage: 1,
      transactionsTableSortKey: 'date',
      transactionsTableSortDirection: 'desc',
      transactionsTableTotalRows: 0,
      transactionsTableRows: [],
      transactionsTableRowsMeta: [],
      transactionToDelete: null,
      transactionsToDelete: [],
      translationPath: 'reports.performance.account_group.'
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    titleMeta () {
      return this.apiData !== null ? (Object.keys(this.apiData).indexOf('accountGroup') > -1 && Object.keys(this.apiData.accountGroup).indexOf('title') > -1 ? this.apiData.accountGroup.title : null) : null
    },
    breadcrumbItems () {
      if (this.group) {
        const isPerformanceReport = ((['income', 'expense']).indexOf(this.group.type) > -1)
        return [
          { text: this.$t('reports._common.reports_and_insights'), to: isPerformanceReport ? '/reports/performance' : '/reports/balance' },
          { text: isPerformanceReport ? this.$t('reports._common.performance_report') : this.$t('reports._common.balance_report'), to: isPerformanceReport ? '/reports/performance/result' : '/reports/balance/report' },
          { text: this.currentUser.strict_accounting_mode ? this.$t('incomes_expenses.submenu.' + this.group.type + '_account') : this.$t('incomes_expenses.submenu.' + this.group.type + '_categories'), to: isPerformanceReport ? '/incomes-expenses/accounts/' + this.group.type : '/assets-liabilities/' + this.group.type },
          { text: '', active: true }
        ]
      } else {
        return []
      }
    },
    currentYearTotal () {
      return this.apiData.aggregated.years[new Date().getFullYear()] ? this.apiData.aggregated.years[new Date().getFullYear()] : 0
    },
    currentYearAvg () {
      return this.currentYearTotal / (new Date().getMonth() + 1)
    },
    groupEditLink () {
      let url = '/assets-liabilities'
      if (['income', 'expense'].indexOf(this.apiData.accountGroup.type) !== -1) {
        url = '/incomes-expenses'
      }
      return url + '/accounts/groups/' + this.apiData.accountGroup.id + '/edit'
    },
    transactionsTableFieldValues () {
      return [
        { key: 'checkbox', label: '', sortable: false },
        { key: 'date', label: this.$t(this.translationPath + 'date'), sortable: true },
        { key: 'title', label: this.$t(this.translationPath + 'title'), sortable: true },
        {
          key: 'amount',
          label: this.$t(this.translationPath + 'amount'),
          sortable: true,
          class: 'text-left text-lg-right',
          formatter: amount => {
            return this.$options.filters.formatAmount(amount, this.currentCOA.locale, this.currentCOA.currency)
          }
        },
        (this.bTableWithBalance ? { key: 'balance', label: this.$t('common.balance'), sortable: false } : null),
        { key: 'account_title', label: this.$t('common.description'), sortable: false },
        { key: 'actions', label: '', class: 'text-right text-lg-right' }
      ]
    },
    currentYearData () {
      const data = {}
      const year = new Date().getFullYear()
      for (let i = 1; i <= 12; i++) {
        const key = year + '-' + (i < 10 ? '0' : '') + i
        data[key] = Object.prototype.hasOwnProperty.call(this.apiData.aggregated.months, key) ? parseFloat(this.apiData.aggregated.months[key]) : 0
      }
      return data
    }
  },
  methods: {
    async loadGroupDetails () {
      await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/${this.$route.params.account_group_id}`)
        .then((response) => {
          this.group = response.data.data
        })
        .catch((error) => {
          console.error(error)
        })
      if (!this.group) {
        return false
      }
    },
    loadInitialData () {
      axios.get(`${process.env.VUE_APP_ROOT_API}/reports/account/group/${this.$route.params.account_group_id}/graphdata?period=all`)
        .then((response) => {
          this.apiData = response.data.data
          this.loadTransactions()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    loadTransactions () {
      if (!this.apiData) {
        return false
      }

      this.transactionsTableBusy = true
      const titleFilter = this.transactionsTitleFilter !== null && this.transactionsTitleFilter.length > 2 ? this.transactionsTitleFilter : null
      const iWithBalance = ['asset', 'liability'].indexOf(this.apiData.accountGroup.type) !== -1 ? 1 : 0
      axios.get(`${process.env.VUE_APP_ROOT_API}/transactions?with_balance=${iWithBalance}&page=${this.transactionsTableCurrentPage}&limit=${this.transactionsTablePerPage}&account_group_id=${this.$route.params.account_group_id}` + '&sort[0][key]=' + this.transactionsTableSortKey + '&sort[0][direction]=' + this.transactionsTableSortDirection + (titleFilter ? '&title=' + titleFilter : ''))
        .then((response) => {
          this.transactionsTableTotalRows = Object.prototype.hasOwnProperty.call(response.data, 'pagination') && Object.prototype.hasOwnProperty.call(response.data.pagination, 'total') ? response.data.pagination.total : 0
          this.transactionsTableRows = response.data.data
          this.transactionsTableRowsMeta = response.data.meta
        })
        .catch((error) => {
          console.error(error)
        })
        .then(() => {
          this.transactionsTableBusy = false
        })
    },
    onTabSwitched (tab) {
      this.activeTab = tab
      if (tab !== this.$route.params.tab) {
        this.$router.push({ params: { tab: tab } })
      }
    },
    changeTab (tab) {
      if (['twelve-months-rolling', 'twelve-months-this-year', 'all-months', 'years'].indexOf(this.$route.params.tab) !== -1) {
        this.$refs.Tabmenu.changeTab(tab)
      }
    },
    onBtnDelete () {
      if (this.apiData.accounts.length) {
        this.$refs.deleteAccountGroupPreventModal.show()
      } else {
        this.$refs.deleteAccountGroupModal.show()
      }
    },
    deleteAccountGroup () {
      axios.delete(`${process.env.VUE_APP_ROOT_API}/accounts/groups/${this.$route.params.account_group_id}`)
        .then((response) => {
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.delete_group_success', { group: this.apiData.accountGroup.title }), {
            title: this.$t(this.translationPath + 'report_title'),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })

          let redirectUrl = '/reports/other/account-plan-v2/'
          if (this.apiData.accountGroup.type === 'liability') {
            redirectUrl += 'liabilities'
          } else {
            redirectUrl += this.apiData.accountGroup.type + 's'
          }
          setTimeout(() => this.$router.push(redirectUrl), 2000)
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.delete_group_fail', { group: this.apiData.accountGroup.title }), {
            title: this.$t(this.translationPath + 'report_title'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .then(() => {
          this.$refs.deleteAccountGroupModal.hide()
        })
    },
    deleteTransaction (modal) {
      const transactionIds = []
      if (this.transactionToDelete && this.transactionToDelete.id) {
        transactionIds.push(this.transactionToDelete.id)
      }
      this.transactionsToDelete.map((item) => {
        transactionIds.push(item.id)
      })

      axios.delete(`${process.env.VUE_APP_ROOT_API}/transactions/with/entries`, { data: { transaction_ids: transactionIds } })
        .then(data => {
          const isPlural = this.transactionsToDelete.length > 1
          modal.hide()
          this.loadInitialData()
          this.$root.$bvToast.toast(this.$t('incomes_expenses.accounts.view.' + (isPlural ? 'toast.success_transactions_delete' : 'toast.success_transaction_delete')), {
            title: this.$t('incomes_expenses.accounts.view.' + (this.transactionsToDelete.length ? 'toast.delete_transactions' : 'toast.delete_transaction')),
            variant: 'success',
            id: 'DeleteTransactionsSuccessModal',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .catch(err => {
          console.error(err)
          this.$refs.deleteTransactionModel.hide()
          this.$refs.deleteTransactionsModal.hide()
          this.$root.$bvToast.toast(this.$t(this.translationPath + (this.transactionsToDelete.length ? 'toast.failed_transactions_delete' : 'toast.failed_transaction_delete')), {
            title: this.$t(this.translationPath + (this.transactionsToDelete.length ? 'toast.delete_transactions' : 'toast.delete_transaction')),
            variant: 'danger',
            id: 'DeleteTransactionsSuccessModal',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    resetDeleteTransactionModals () {
      this.transactionToDelete = null
      this.transactionsToDelete = []
    },
    onTransactionsTablePerPageUpdate (val) {
      this.transactionsTablePerPage = val
      this.loadTransactions()
    },
    onTransactionsTableCurrentPageUpdate (val) {
      this.transactionsTableCurrentPage = val
      this.loadTransactions()
    },
    onTransactionsTableFiltered (val) {
      this.transactionsTableFilter = val
    },
    onTransactionsTableSort (sortData) {
      this.transactionsTableSortKey = sortData.sortBy
      this.transactionsTableSortDirection = sortData.sortDesc ? 'desc' : 'asc'
      this.loadTransactions()
    },
    onBtnDeleteTransaction (transaction) {
      this.transactionToDelete = transaction
      this.$refs.deleteTransactionModal.show()
    },
    onBtnDeleteTransactions (transactions) {
      this.transactionsToDelete = transactions
      this.$refs.deleteTransactionsModal.show()
    }
  },
  created () {
    this.$bvToast.toast(this.$t('common.toasts.loading_data'), {
      title: this.$t(this.translationPath + 'report_title'),
      variant: 'info',
      solid: true
    })
    this.loadGroupDetails()
    this.loadInitialData()
  },
  mounted () {
    this.changeTab(this.$route.params.tab)
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$route.params.tab' () {
      this.changeTab(this.$route.params.tab)
    }
  }
}
</script>

<style lang="scss">
.account-group-report-wrapper {
  width: 100%;

  .tableYearDropDown {
    &.custom-select {
      width: auto;
      margin-left: 10px;
      height: auto !important;
      min-height: 11px;
    }
  }
  .invisible-card{
    border: 0;
    .card-body{padding-top:0;}
    .card-title{
      font-weight: 400;
      color:#ACAEBA;
      margin-bottom:3px;
      font-size:16px;
    }
  }

  .accounts-table-card {
    .card-body {
      padding: 0;
    }
  }

  .actionButtons {
    font-size: 14px;

    .flaticon {
      font-size: 14px;
      margin-right: 2px;
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
      @media screen and (min-width: 992px) and (max-width: 1072px) {
        font-size: 12px;
      }
    }

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
    @media screen and (min-width: 992px) and (max-width: 1072px) {
      font-size: 12px;
    }
  }
}
</style>
