<template>
  <b-container fluid :class="{'reports-performance-savings-ratio-percentage-chart': true, 'pb-5': type !== 'allMonths'}">
    <div class="empty_graph" style="height:250px;" v-if="noTransactions">
      <b-alert variant="warning" class="mx-auto" style="max-width:1440px;" show>
        <p class="col-lg-8 col-md-10 font-weight-medium text-center mx-auto" style="font-size:16px">
          {{ $t('incomes_expenses.accounts.view.no_transaction_no_chart') }}
        </p>
      </b-alert>
    </div>
    <bar-chart v-if="type !== 'allMonths'"
      :chart-data="chartData"
      :options="chartOptions"
      :chart-id="'savings-ratio-percentage-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>

    <d3-bar-chart v-if="type === 'allMonths'"
      :graphData="chartData"
      :monthKeys="d3MonthLabels"
      :currency="currencyISO"
      :currencySymbol="currencySymbol"
      :label="'Value'"
    ></d3-bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import D3BarChart from '@/components/d3charts/bar-chart/index'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'AccountGroupReport2Chart',
  components: { BarChart, D3BarChart },
  mixins: [chartOptions],
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    currencySymbol: {
      type: String,
      default: () => 'kr'
    },
    currencyISO: {
      type: String,
      default: () => 'SEK'
    },
    type: {
      type: String,
      default: 'allMonths'
    },
    noTransactions: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chartData: {}
    }
  },
  computed: {
    monthLabels () {
      return [
        this.$t('common.months.jan'),
        this.$t('common.months.feb'),
        this.$t('common.months.mar'),
        this.$t('common.months.apr'),
        this.$t('common.months.may'),
        this.$t('common.months.jun'),
        this.$t('common.months.jul'),
        this.$t('common.months.aug'),
        this.$t('common.months.sep'),
        this.$t('common.months.oct'),
        this.$t('common.months.nov'),
        this.$t('common.months.dec')
      ]
    },
    monthLabelsFull () {
      return [
        this.$t('common.months.january'),
        this.$t('common.months.february'),
        this.$t('common.months.march'),
        this.$t('common.months.april'),
        this.$t('common.months.may'),
        this.$t('common.months.june'),
        this.$t('common.months.july'),
        this.$t('common.months.august'),
        this.$t('common.months.september'),
        this.$t('common.months.october'),
        this.$t('common.months.november'),
        this.$t('common.months.december')
      ]
    },
    d3MonthLabels () {
      const labels = {}
      for (let i = 1; i <= 12; i++) {
        const key = (i < 10 ? '0' : '') + i
        labels[key] = { full: this.monthLabelsFull[i - 1], short: this.monthLabels[i - 1] }
      }
      return labels
    },
    rollingIndexes () {
      const currentMonth = new Date().getMonth() + 1
      const year = new Date().getFullYear()
      let dataIndex = ''
      const dataIndexes = []

      for (let i = 1; i <= 12; i++) {
        dataIndex = (i <= currentMonth ? year : year - 1) + '-' + (i < 10 ? '0' : '') + i
        dataIndexes.push(dataIndex)
      }
      dataIndexes.sort()

      return dataIndexes
    },
    yearIndexes () {
      const year = new Date().getFullYear()
      let dataIndex = ''
      const dataIndexes = []

      for (let i = 1; i <= 12; i++) {
        dataIndex = year + '-' + (i < 10 ? '0' : '') + i
        dataIndexes.push(dataIndex)
      }

      return dataIndexes
    },
    labelDataRolling () {
      const labelData = []

      let year = new Date().getFullYear()
      if (new Date().getMonth() < 11) {
        year--
      }

      for (const i in this.rollingIndexes) {
        const monthKey = this.rollingIndexes[i]
        let label = this.monthLabels[parseInt(monthKey.slice(5)) - 1]
        if (i === '0') {
          label += ', ' + year
        } else if (parseInt(monthKey.slice(5)) === 1) {
          label += ', ' + (year + 1)
        }
        labelData.push(label)
      }

      return labelData
    },
    labelDataYear () {
      const labelData = []
      for (const i in this.yearIndexes) {
        let label = this.monthLabels[i]
        if (i === '0') {
          label += ', ' + new Date().getFullYear()
        }
        labelData.push(label)
      }
      return labelData
    },
    amountDataRolling () {
      const amountData = []
      for (const i in this.rollingIndexes) {
        amountData.push(Object.prototype.hasOwnProperty.call(this.data, this.rollingIndexes[i]) ? parseFloat(this.data[this.rollingIndexes[i]]) : 0)
      }
      return amountData
    },
    amountDataYear () {
      const amountData = []
      for (const i in this.yearIndexes) {
        amountData.push(this.data[this.yearIndexes[i]])
      }
      return amountData
    }
  },
  methods: {
    setOptions () {
      this.chartOptions.legend.display = false
      /* Modify Y-mouse over */
      this.chartOptions.scales.yAxes[0].ticks.callback = (value, index, values) => {
        return formatNumberToFull(value) + ' ' + this.currencySymbol
      }

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = (tooltipItem) => {
        tooltipItem.yLabel = Math.floor(tooltipItem.yLabel)
        return this.$t('common.amount') + ': ' + formatNumberToFull(tooltipItem.yLabel) + ' ' + this.currencySymbol
      }
    },
    fillChart () {
      this.setOptions()

      let labelData = []
      let amountData = []

      switch (this.type) {
        case 'rolling':
          labelData = this.labelDataRolling
          amountData = this.amountDataRolling
          break
        case 'year':
          labelData = this.labelDataYear
          amountData = this.amountDataYear
          break
        case 'annual':
          for (const year in this.data) {
            labelData.push(year)
            amountData.push(this.data[year])
          }
          break
      }

      if (this.type !== 'allMonths') {
        this.chartData = {
          labels: labelData,
          datasets: [
            {
              label: this.$t('common.amount'),
              data: amountData,
              borderColor: '#8cccad',
              backgroundColor: '#8cccad',
              hoverBackgroundColor: '#dda3a2'
            }
          ]
        }
      } else {
        this.chartData = this.data
      }
    }
  },
  created () {
    this.fillChart()
  },
  watch: {
    currencySymbol () {
      this.setOptions()
    },
    data: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  }
}
</script>
