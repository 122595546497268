<template>
  <b-row no-gutters>
    <b-col>
      <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto mb-4 mt-4">
        <template v-slot:header>
          <b-row>
            <b-col sm="6">
              <h2>{{ $t(translationPath + 'annual_data',{year:year}) }}</h2>
            </b-col>
            <b-col sm="6" class="text-right">
              <b-form-select id="year" v-model="year" :options="yearOptions" class="tableYearDropDown"></b-form-select>
            </b-col>
          </b-row>
        </template>
        <div class="overflow-x-scroll">
        <b-table class="spirecta-simple-table report-account-group-accounts-table detailed-table mb-0" show-empty hover responsive striped
                 stacked="xl"
                 :items="rows"
                 :fields="fieldValues"
                 :emptyText="$t('common.no_category')"
                 :busy="busy"
        >
          <template v-slot:table-busy>
            <loader/>
          </template>

          <template v-slot:head(title)>
          </template>

          <template v-slot:cell(title)="row">
            <template v-if="row.item.id">
              <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'">{{ row.value }}</b-link>
            </template>
            <template v-else>{{ row.value }}</template>
          </template>

          <template v-for='(field, index) in amountColumns' :slot='"cell("+field+")"' slot-scope='row'>
            <b-link class="amount-link" v-if="currentCOA.locale" :key="index" :to="transactionsUrl(row)">{{ row.item[field] | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, false, 0) }}</b-link>
          </template>
        </b-table>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import Loader from '@/components/common/Loader'
import axios from 'axios'

export default {
  name: 'AccountGroupReport2AccountsTable',
  filters: { formatAmount },
  components: { Loader },
  props: {
    accounts: {
      type: Array,
      default: () => []
    },
    groupType: {
      type: String,
      default: null
    },
    totalColumn: {
      type: Boolean,
      default: true
    },
    averageColumn: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      busy: true,
      amountColumns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'total', 'avg'],
      translationPath: 'reports.performance.account_group.',
      yearOptions: [],
      year: null
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    fieldValues () {
      const fieldsArray = [
        { key: 'title', label: this.$t('common.category'), class: 'text-left' },
        { key: '1', label: this.$t('common.months.jan') },
        { key: '2', label: this.$t('common.months.feb') },
        { key: '3', label: this.$t('common.months.mar') },
        { key: '4', label: this.$t('common.months.apr') },
        { key: '5', label: this.$t('common.months.may') },
        { key: '6', label: this.$t('common.months.jun') },
        { key: '7', label: this.$t('common.months.jul') },
        { key: '8', label: this.$t('common.months.aug') },
        { key: '9', label: this.$t('common.months.sep') },
        { key: '10', label: this.$t('common.months.oct') },
        { key: '11', label: this.$t('common.months.nov') },
        { key: '12', label: this.$t('common.months.dec') }
      ]
      if (this.totalColumn) {
        fieldsArray.push({ key: 'total', label: this.$t('common.total'), sortable: false, class: 'dark-left-border' })
      }
      if (this.averageColumn) {
        fieldsArray.push({ key: 'avg', label: this.$t('common.per_month'), sortable: false })
      }
      return fieldsArray
    }
  },
  methods: {
    async loadYears () {
      await axios.get(`${process.env.VUE_APP_ROOT_API}/transactions/years?account_group_id=${this.$route.params.account_group_id}&account_type=${this.groupType}`)
        .then(response => response.data.data)
        .then(responseData => {
          if (responseData.years.length) {
            this.yearOptions = responseData.years
            this.year = responseData && Object.prototype.hasOwnProperty.call(responseData, 'years') && responseData.years.length > 0 ? responseData.years[0] : new Date().getFullYear()
            // this.fillTable()
          } else {
            this.yearOptions = [new Date().getFullYear()]
            this.year = this.yearOptions[0]
          }
        }).catch(err => {
          console.error(err)
        })
    },
    fillTable () {
      this.busy = true
      this.rows = []

      if (!this.accounts.length) {
        this.busy = false
        return false
      }

      const resultRow = {
        title: this.$t('common.sum'),
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        total: 0,
        avg: 0,
        id: 0
      }
      this.accounts.map((account) => {
        const row = {
          id: account.id,
          title: account.title,
          total: 0
        }

        let avgDelimiter = 0
        for (let month = 1; month <= 12; month++) {
          const dateKey = this.year + '-' + (month < 10 ? '0' : '') + month
          row[month] = account.totals.year_months[dateKey] ? parseFloat(account.totals.year_months[dateKey]) : 0
          resultRow[month] += row[month]
          if (row[month]) {
            row.total += row[month]
            resultRow.total += row[month]
            avgDelimiter++
          }
        }
        row.avg = avgDelimiter ? row.total / avgDelimiter : row.total
        resultRow.avg += row.avg
        this.rows.push(row)
      })
      this.rows.push(resultRow)
      this.busy = false
    },
    transactionsUrl (row) {
      let url = '/transactions/view?account_group_id=' + this.$route.params.account_group_id
      if (row.item.id) {
        url += '&account_id=' + row.item.id
      }

      const year = this.year
      let startDate = year + '-01-01'
      let endDate = year + '-12-31'

      if (!isNaN(parseInt(row.field.key))) {
        let month = parseInt(row.field.key)
        let lastDay = 31
        switch (month) {
          case 4:
          case 6:
          case 9:
          case 11:
            lastDay = 30
            break
          case 2:
            lastDay = 28
            if ((year % 4 && year % 100) || !year % 400) {
              lastDay = 29
            }
        }

        if (month < 10) {
          month = '0' + month
        }

        startDate = year + '-' + month + '-01'
        endDate = year + '-' + month + '-' + lastDay
      }
      url += '&start_date=' + startDate + '&end_date=' + endDate

      return url
    }
  },
  created () {
    this.fillTable()
  },
  watch: {
    accounts: {
      deep: true,
      handler () {
        this.fillTable()
      }
    },
    groupType: function () {
      if (this.groupType) {
        this.loadYears()
      }
    },
    year: function (value) {
      if (value) {
        this.$emit('year-updated', value)
        this.fillTable()
      }
    }
  }
}
</script>

<style lang="scss">
.account-group-report-wrapper {
  .overflow-x-scroll {
    width: 100%;
    overflow-x: scroll;
  }
  .report-account-group-accounts-table {
    .dark-left-border {
      border-left: 1px solid #000;
      @media screen and (max-width: 1200px) {
        border-left: none;
      }
    }
    tr:last-child td {
      font-weight: bold;
      background: #fff;
    }
    thead {
      th {
        text-align: right;
        border-top: none;
        &:nth-child(1) {
          @media screen and (min-width: 768px) {
            // min-width: 243px;
          }
        }
      }
    }
    tbody {
      td {
        text-align: right;
      }
      td:first-child {
        text-align: left;
      }
      tr:last-child td{
        border-top: 1px solid #ACAEBA;
        border-bottom: 2px solid #ACAEBA;
        @media screen and (max-width: 1200px) {
          border-top: 1px dashed #ACAEBA;
          border-bottom:0;
        }
      }
    }
    a {
      color: #212529;
    }
    a:hover {
      color: #74788D;
    }
    .amount-link{font-size:95%;}
  }
}
</style>
