<template>
  <div id="performance-report-transaction-table-wrapper" class="table-container">
    <b-row no-gutters v-show="displayDeleteButton" class="print-none">
      <b-col cols="12" class="float-left px-0 mt-2 actionButtons">
         <b-button variant="outline-danger"  class="btn-sm" @click="deleteMultipleTransactions()"><i class="flaticon stroke trash-3 mr-2"></i>{{$t('transactions.view.button.mass_delete')}}</b-button>
      </b-col>
    </b-row>
    <b-table :class="'spirecta-simple-table performance-report-transaction-table mt-3 ' + (this.bTableWithBalance ? 'table_with_balance' : 'table_no_balance') " show-empty hover responsive striped foot-clone no-footer-sorting
             ref="transactionsTable"
             stacked="lg"
             :items="transactionItems"
             :fields="fieldValues"
             :per-page="itemsPerPage"
             :emptyText="emptyText"
             :current-page="1"
             :no-local-sorting="true"
             :busy="busy"
             :tbodyTrClass="trClass"
             @sort-changed="onSortChanged"
    >
      <!--loader-->
      <template v-slot:table-busy>
        <loader/>
      </template>
      <!--loader ends here-->
      <template v-slot:head(date) v-if="enableTransactionHeading">
        <span class="table-title">{{$t('incomes_expenses.reports.account_group.transactions')}}</span>
      </template>
      <template v-slot:head(checkbox)>
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="itemsToDelete.length > 0 && itemsToDelete.length < transactionItems.length"
          @input="toggleSelectAll"
        ></b-form-checkbox>
      </template>

      <!-- Filter -->
      <template slot="top-row">
        <td :colspan="this.colspan">
          <div class="d-flex align-items-center">
            <i class="fa fa-search text-secondary"></i>
            <b-form-input v-model="filter" size="sm" :placeholder="$t('common.filter_placeholder')" @input="onFiltered" />
          </div>
        </td>
      </template>

      <template v-slot:cell(checkbox)="row">
        <template>
          <b-form-checkbox v-model="row.item.isSelected" @change="(value) => onTransactionRowCheckboxToggle(value, row.item.id)"></b-form-checkbox>
        </template>
      </template>

      <template v-slot:cell(date)="row">
        {{ row.item.date.substr(0, 10) }}
      </template>

      <template v-slot:cell(title)="row">
        <!--<template v-if="row.item.type ==='transaction'">-->
        <b-link :to="'/transactions/view/transaction/' + row.item.id" class="text-black font-weight-bold">{{ row.item.title }}</b-link>
        <!--</template>-->
        <!--<template v-else-if="row.item.type ==='sumrow'">
          <span class="font-weight-medium float-right">{{ $t(translationPath + 'total_sum_rows_above')}}:</span>
        </template>-->
      </template>

      <template v-slot:cell(amount)="row">
        <!--<template v-if="row.item.type ==='transaction'">-->
        <span :class="[
          'font-weight-medium',
          row.item.transaction_subtype ? ('transaction_' + row.item.transaction_subtype) : '',
          row.item.balance_change ? ('transaction_' + row.item.balance_change) : ''
          ]">
          <span style="text-wrap: none">{{ $options.filters.formatAmount(Math.abs(row.item.amount), currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, (bTableWithBalance ? 2 : 0)) }}</span>
        </span>
        <!--</template>-->
        <!--<template v-else-if="row.item.type ==='sumrow'">
          <span class="font-weight-medium">{{ $options.filters.formatAmount(transactionsMetaData.sum, currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}</span>
        </template>-->
      </template>

      <template v-slot:cell(balance)="row">
        <!--<template v-if="row.item.type ==='transaction'">-->
        <span class='font-weight-medium' style="text-wrap: none;">{{ $options.filters.formatAmount(row.item.balance, currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, (bTableWithBalance ? 2 : 0)) }}</span>
        <!--</template>-->
      </template>

      <template v-slot:cell(account_title)="row">
        <!--<template v-if="row.item.type ==='transaction'">-->
        <i18n :path="'common.transaction_subtypes.'+row.item.transaction_subtype" tag="span" class="text-dark" style="font-size:90%">
          <template v-slot:cr_account v-if="Object.prototype.hasOwnProperty.call(row.item, 'cr_entries') && ( 0 in row.item.cr_entries)"><b-link :href="'/reports/performance/accounts/'+row.item.cr_entries[0].account_id+'/view'" class="text-dark font-weight-bold">{{ row.item.cr_entries[0].account_title }}</b-link></template>
          <template v-slot:dr_account v-if="Object.prototype.hasOwnProperty.call(row.item, 'dr_entries') && ( 0 in row.item.dr_entries)"><b-link :href="'/reports/performance/accounts/'+row.item.dr_entries[0].account_id+'/view'" class="text-dark font-weight-bold">{{ row.item.dr_entries[0].account_title }}</b-link></template>
        </i18n>
      </template>

      <!-- EDIT / DELETE BUTTONS -->
      <template v-slot:cell(actions)="row">
        <template>
          <b-link href="#" @click="deleteTransaction(row.item)" class="mr-3 delete-link" :title="$t(translationPath + 'delete_transaction')">
            <i class="flaticon stroke trash-3"></i>
          </b-link>
          <b-link  @click="navigateToTransaction(row.item.id)" class="mr-2 open-link" :title="$t(translationPath + 'open_transaction')">
            <i class="flaticon solid right-circle-2 "></i>
          </b-link>
        </template>
      </template>
      <template v-slot:foot(checkbox)>
        &nbsp;
      </template>
      <template v-slot:foot(date)>
        &nbsp;
      </template>
      <template v-slot:foot(title)>
        <span class="font-weight-medium float-none float-lg-right">{{ $t('common.sum')}}:</span>
      </template>
      <template v-slot:foot(amount)>
        <span class="font-weight-medium">{{ $options.filters.formatAmount(transactionsMetaData.sum, currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, (bTableWithBalance ? 2 : 0)) }}</span>
      </template>
      <template v-slot:foot(balance)>
        &nbsp;
      </template>
      <template v-slot:foot(account_title)>
        <span class="text-secondary text-small">
          {{ $t('common.average')}} {{ $options.filters.formatAmount(transactionsMetaData.average, currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }},
          {{ $t('common.median')}} {{ $options.filters.formatAmount(transactionsMetaData.median, currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
        </span>
      </template>
      <template v-slot:foot(actions)>
        &nbsp;
      </template>
    </b-table>
    <pagination :totalRows="itemsTotal" :currentPage.sync="currentPage" :perPage.sync="itemsPerPage"></pagination>
  </div>
</template>

<script>
import pagination from '@/components/common/pagination'
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'
import Loader from '@/components/common/Loader'

export default {
  name: 'LedgerTable',
  components: {
    pagination,
    Loader
  },
  filters: {
    formatAmount,
    displayAccountTitle,
    formatAmount2 (amount, locale, currency) {
      return Number(amount).toLocaleString(locale) + ' ' + currency
    }
  },
  plugins: [
    '@/assets/filters'
  ],
  props: {
    transactions: Array,
    perPage: {
      type: Number,
      default: 10
    },
    busy: {
      type: Boolean,
      default: false
    },
    accountLinked: {
      type: Boolean,
      default: false
    },
    offsetAccountLinked: {
      type: Boolean,
      default: true
    },
    enableTransactionHeading: {
      type: Boolean,
      default: false
    },
    emptyText: {
      type: String,
      default: 'There are no records to show'
    },
    displayAccount: {
      type: Boolean,
      default: false
    },
    displayOffsetAccount: {
      type: Boolean,
      default: false
    },
    totalRows: {
      type: Number,
      default: 0
    },
    enableRowDetailed: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: Number,
      default: 0
    },
    accountType: { type: String },
    transactionsMetaData: null
  },
  data () {
    return {
      transactionItems: [],
      itemsPerPage: null,
      currentPage: 1,
      translationPath: 'reports.other.ledger.report.table.',
      filter: null,
      allSelected: false,
      sortBy: null,
      sortDesc: false,
      sortCompareLocale: 'sv', /* TODO: set by users default  */
      sortDirection: 'asc',
      pageOptions: [2, 5, 10, 15, 25],
      itemsDetailed: null,
      colspan: 6,
      bTableWithBalance: false
    }
  },
  created () {
    this.itemsPerPage = this.perPage
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    itemsTotal () {
      return this.totalRows
    },
    itemsToDelete () {
      return this.transactionItems.filter(item => item && Object.prototype.hasOwnProperty.call(item, 'isSelected') && item.isSelected)
    },
    displayDeleteButton () {
      return this.itemsToDelete.length > 0
    },
    fieldValues () {
      return [
        { key: 'checkbox', label: '', sortable: false },
        { key: 'date', label: this.$t('common.date'), sortable: true },
        { key: 'title', label: this.$t('common.title'), sortable: true },
        {
          key: 'amount',
          label: this.$t('common.amount'),
          sortable: true,
          class: 'text-left text-lg-right',
          formatter: amount => {
            return this.$options.filters.formatAmount(Math.abs(amount), this.currentCOA.locale, this.currentCOA.currency)
          }
        },
        (this.bTableWithBalance ? { key: 'balance', label: this.$t('common.balance'), sortable: false } : null),
        { key: 'account_title', label: this.$t('common.description'), sortable: false },
        // { key: 'type', label: this.$t(this.translationPath + 'type'), sortable: false },
        { key: 'actions', label: '', class: 'text-right text-lg-right' }
      ]
    }
  },
  methods: {
    navigateToTransaction (id) {
      const params = Object.assign({}, {
        back_route: this.$route.name,
        transaction_id: id,
        back_params: {
          account_id: this.$route.params.account_id,
          per_page: this.itemsPerPage,
          tab: 'twelve-months-rolling'
        }
      })
      this.$router.push({
        name: 'transactions-create-single',
        params
      })
    },
    onFiltered () {
      this.$emit('on-filtered', this.filter)
    },
    onSortChanged (sortData) {
      this.$emit('on-sort', sortData)
    },
    deleteTransaction (item) {
      this.$emit('delete-transaction', item)
    },
    deleteMultipleTransactions () {
      const deletedItems = this.transactionItems.filter(item => item && Object.prototype.hasOwnProperty.call(item, 'isSelected') && item.isSelected)
      this.$emit('delete-multiple-transactions', deletedItems)
    },
    toggleSelectAll (value) {
      this.transactionItems = this.transactionItems.map(item => {
        if (value) {
          return Object.assign(item, { isSelected: true })
        } else {
          return Object.assign(item, { isSelected: false })
        }
      })
    },
    onTransactionRowCheckboxToggle (value, itemId) {
      this.transactionItems = this.transactionItems.map((items) => {
        if (items.id === itemId) {
          return Object.assign(items, { isSelected: value })
        }
        return items
      })
    },
    refresh () {
      this.$refs.transactionsTable.refresh()
    },
    trClass (item, type) {
      if (item === null) {
        return ''
      }
      return item.type
    }
  },
  watch: {
    transactions: {
      handler () {
        if (this.transactions) {
          this.transactionItems = this.transactions
        }
        this.allSelected = false
        this.onFiltered(this.transactionItems)
      },
      immediate: true
    },
    itemsPerPage (newVal, oldVal) {
      if (oldVal !== null) {
        this.$emit('update:perPage', newVal)
      }
    },
    accountType () {
      this.bTableWithBalance = (this.accountType === 'asset' || this.accountType === 'liability')
      this.colspan = this.bTableWithBalance ? 7 : 6
    },
    currentPage (newVal) {
      this.$emit('update:currentPage', newVal)
    }
  }
}
</script>

<style lang="scss">
#performance-report-transaction-table-wrapper {
  .table-title {
    font-size: 24px;
    color: #434343;
    line-height: 32px;
  }
  .displayHidden {
    display: none !important;
  }

  .open-link{color:#74788D}
  .open-link:hover{text-decoration: none;color:#8cccad}

  .delete-link{color:#74788D}
  .delete-link:hover{text-decoration: none;color:#dda3a2}

  tr.sumrow td{
    border-top:2px solid #cacaca;
    border-bottom:1px solid #cacaca;
    background: white;
  }
  tbody {
    tr {
      td {
        &:nth-child(4) {
          @media screen and (min-width: 992px) {
            white-space: nowrap;
          }
        }
        &:nth-child(5) {
          @media screen and (min-width: 992px) {
            white-space: nowrap;
          }
        }
      }
    }
  }
  tfoot {
    tr{
      border-top: 2px solid #ACAEBA;
      border-bottom: 2px solid #ACAEBA;
      @media (max-width: 991.98px) {
        width: 100%;
        display: block;
      }
      th {
        &:nth-child(1) {
          @media (max-width: 991.98px) {
            display: none;
          }
        }
        &:nth-child(2) {
          @media (max-width: 991.98px) {
            display: none;
          }
        }
        &:nth-child(6) {
          @media (max-width: 991.98px) {
            display: none;
          }
        }
        &:nth-child(7) {
          @media (max-width: 991.98px) {
            display: none;
          }
        }
        @media (max-width: 991.98px) {
          display: block;
          width: 100%;
          float: none;
          text-align: center !important;
          span {
            text-align: center !important;
          }
        }
      }
    }
    @media (max-width: 991.98px) {
      display: block;
    }
  }
  @media only screen and (min-width: 992px) {
    .table_with_balance tbody tr td {
      cursor: pointer;
      &:nth-child(2) {width:120px;}
      &:nth-child(3) {min-width:300px;}
      &:nth-child(4) {min-width:130px;}
      &:nth-child(5) {min-width:130px;}
      &:last-child{width:50px;}
    }
    .table_no_balance tbody tr td {
      cursor: pointer;
      &:nth-child(2) {width:120px;}
      &:nth-child(3) {min-width:300px;}
      &:nth-child(4) {width:120px;}
      &:last-child{width:50px;}
    }
  }
}
</style>
